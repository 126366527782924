import React from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import posed from 'react-pose';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Actions
import { purchasesActions } from '../actions'

import PaymentForm from '../components/PaymentForm'
import imgStudents from '../assets/undraw_exams.svg'
import imgInstructor from '../assets/undraw_teacher.svg'

// Animated components
const Pill = posed.span({
    active   : { color: '#FFF', background: '#007bff' },
    inactive : { color: '#666', background: '#f8f9fa' },
    success  : { color: '#007bff', background: '#FFF', border: '1px solid #007bff' }
})

class PurchaseWizard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            item: this.props.item || null,
            rvh: '75vh',
            current: 0,
            steps: [1,2,3,4],
            labels: ['Payer', 'Players', 'Payment', 'Complete'],
            // Generic
            payer: null,
            payment_time: null,
            quantity: null,
            // Pay later
            pl_name: (props.user.name + ' ' + props.user.lastname) || null,
            pl_email: props.user.email || null,
            pl_address: null,
            result: null
        }

        // Bindings 
        this.prev             = this.prev.bind(this);
        this.next             = this.next.bind(this);
        this.goto             = this.goto.bind(this);
        this.finish           = this.finish.bind(this);
        this.setValue         = this.setValue.bind(this);
        this.handleChange     = this.handleChange.bind(this);
        this.generatePurchase = this.generatePurchase.bind(this);
        this.handleStripePayment = this.handleStripePayment.bind(this);

    }

    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    goto(s){
        this.setState({ current: s });
    }

    finish() {
        if( typeof this.props.onFinish !== 'undefined' )
            this.props.onFinish();
        else
            console.log('Finish')
    }

    setValue(field, value){
        this.setState({ [field]: value }, this.next )
    }

    handleChange(e){
        this.setState({ [e.target.name]: e.target.value })
    }

    generatePurchase(){

        const { payer, payment_time, quantity } = this.state
        const { pl_name, pl_email, pl_address } = this.state;
        const { dispatch, item, user } = this.props

        dispatch(purchasesActions.create(
            item, 
            user.email, 
            payer, 
            payment_time, 
            quantity,
            { pl_name, pl_email, pl_address },
            result => {
                this.setState({ result: result })
                this.next()
            }
        ))
    }

    handleStripePayment(payment){

        const { payer, payment_time, quantity } = this.state
        const { dispatch, item, user } = this.props

        dispatch(purchasesActions.create(
            item, 
            user.email, 
            payer, 
            payment_time, 
            quantity,
            { payment },
            result => {
                this.setState({ result: result })
                this.next()
            }
        ))

    }

    render() {

        const { current, item, payer, payment_time, quantity } = this.state;
        const { pl_name, pl_email, result, steps, labels } = this.state;
        const { user } = this.props;

        const loginLink = item.direct_url && item.direct_url.length > 0 ? item.direct_url : `/oauth/authorize?client_id=${item.client_id}&response_type=code&redirect_uri=${item.redirect_url}&scope=student`

        const stepStyle = {
            padding: '7px 9px',
            fontSize: '12px',
            fontWeight: '600',
            cursor: 'pointer'
        }
        const stepBubbles = steps.map( (t,ti) => {
            const cols = steps.length > 0 ? 12/steps.length : 12;
            let pose = ( ti <= this.state.current ) ? 'active' : 'inactive';
            pose = ti < this.state.current ? 'success' : pose
            return <div 
                key={'step-pill-' + ti} 
                onClick={ () => ti < this.state.current ? this.goto(ti) : null }
                className={'step-pill text-center col-' + cols + ' active' }>
                <Pill className="badge badge-pill badge-primary" pose={pose} style={stepStyle}>
                    {t}
                </Pill>
                <small className={`ml-2 ${ti < this.state.current ? 'font-weight-bold' : ''}`}>{labels[ti]}</small>
            </div>});

        const content = [
            <div className="text-center v-center pb-4">

                <h6 className="text-primary font-weight-bold mt-0 mb-3">PAYER DETAILS</h6>
                <p className="lead mt-0 mb-3 mb-lg-5">
                    Select who will pay for access to <b>{item.name}</b>
                </p>

                <div className="row">
                    <div className="col-12 col-lg-5 offset-lg-1">
                        <div className="card" onClick={ e => this.setValue('payer', 'instructor') }>
                            <img src={imgInstructor} className="d-none d-lg-inline-block card-img-top" style={{ height: '120px' }} alt="..." />
                            <div className="card-body">
                                <p className="card-text">
                                    Instructor purchases bulk-access on behalf of learners.
                                </p>
                                <button className="btn btn-outline-primary stretched-link">Instructor Pays</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 mt-3 mt-lg-0">
                        <div className="card" onClick={ e => this.setValue('payer', 'student') }>
                            <img src={imgStudents} className="d-none d-lg-inline-block card-img-top" style={{ height: '120px' }} alt="..." />
                            <div className="card-body">
                                <p className="card-text">
                                    Each individual learner will pay to unlock access.
                                </p>
                                <button className="btn btn-outline-primary stretched-link">Learner Pays</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>,
            <div className="text-center v-center pb-4">

                <h6 className="text-primary font-weight-bold mt-0 mb-3">PLAYERS DETAILS</h6>
                <p className="lead mt-0 mb-3 mb-lg-5">
                    How many learners will participate in this game session?<br/>
                    <small className="font-italic font-weight-light">
                        Estimates are ok. You can add learners later.
                    </small>
                </p>

                <div className="form-group row">
                    <label className="col-6 col-lg-4 offset-lg-2 col-form-label" style={{ lineHeight: 2 }}>Price per Learner</label>
                    <div className="col-6 col-lg-4">
                        <button className="btn btn-block btn-light mt-2">
                            ${item.price}
                        </button>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-6 col-lg-4 offset-lg-2 col-form-label" style={{ lineHeight: 2 }}># of Learners</label>
                    <div className="col-6 col-lg-4">
                        <input 
                            type="number"
                            className="form-control form-control text-center mt-2" 
                            placeholder="Example: 20" 
                            name="quantity"
                            value={quantity}
                            onChange={this.handleChange}
                            />
                    </div>
                </div>

                { payer === 'instructor' && <div className="form-group row d-none d-lg-flex">
                    <label className="col-4 offset-2 col-form-label" style={{ lineHeight: 2 }}>Amount Due</label>
                    <div className="col-4">
                        <button className="btn btn-block btn-light mt-2">
                            $<CountUp end={ quantity * item.price } duration={1} separator="," />
                        </button>
                    </div>
                </div> }

                { payer === 'instructor' && <div className="mb-3 text-center">
                    <button 
                        className={`btn ${ quantity > 0 ? 'btn-outline-primary' : 'btn-secondary' } mt-2`}
                        disabled={ quantity > 0 ? false : true }
                        onClick={ e => this.setValue('payment_time', 'now') }>
                        { payer === 'instructor' ? 'Pay' : '' } $<CountUp end={ quantity * item.price } duration={1} separator="," />
                    </button>
                </div> }

                { payer === 'instructor' && <div>
                    {/* <button 
                        className={`btn btn-lg ${ quantity > 0 ? 'btn-outline-primary' : 'btn-secondary' }`}
                        disabled={ quantity > 0 ? false : true }
                        onClick={ e => this.setValue('payment_time', 'now') }>
                        Pay Now
                    </button> */}
                    <div className="mt-4 text-muted">
                        <small>
                            Or choose {' '}
                            <a href="#" onClick={ () => this.setValue('payment_time', 'later') }>
                                invoice me later
                            </a> if you prefer to pay later.
                        </small>
                    </div>
                </div> }

                { payer === 'student' && <div>
                    <button 
                        className="btn mt-4 btn-outline-primary" 
                        disabled={ quantity > 0 ? false : true }
                        onClick={ e => this.setValue('payment_time', 'later') }>
                        Next
                    </button>
                </div> }

            </div>,
            <div className="text-center v-center pb-4">

                <h6 className="text-primary font-weight-bold mt-0 mb-3">PAYMENT DETAILS</h6>

                { payer === 'student' && <div>
                    <p className="lead mt-0">
                        Create a student pay game session now.
                    </p>
                    <p className="lead mt-0 mb-3 mb-lg-5">
                        Students added to the game session will be prompted to pay to access their unique invitation.
                    </p>
                    <button className="btn btn-outline-primary btn-lg mt-3 mb-4" onClick={this.generatePurchase}>Create Game Session</button>
                    <small className="form-text text-muted">
                        By creating a game session you are acknowledging that you accept our <a href="https://simcase.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>.
                    </small>
                </div> }

                { payer === 'instructor' && payment_time === 'now' && <div>
                    <p className="lead mt-0 mb-3 mb-lg-5">
                        {/* Please add your credit card details bellow (we use <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a>, a secure payment platform). */}
                        We utilize <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a> as a secure, third-party payment platform.<br/>
                        <small className="font-italic font-weight-light">
                            Pay now or choose to <span className="btn-link font-weight-light" onClick={ e => this.setState({ payment_time: 'later' }) }>receive an invoice</span> later.
                        </small>
                    </p>
                    <PaymentForm 
                        client={item._id}
                        quantity={quantity}
                        fields={['name', 'email']}
                        values={{ name: `${user.name} ${user.lastname}`, email: user.email }}
                        onSuccess={ this.handleStripePayment } />
                    <small className="form-text text-muted mt-3">
                        By confirming payment you are acknowledging that you accept our <a href="https://simcase.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>.
                    </small>
                </div> }

                { payer === 'instructor' && payment_time === 'later' && <div className="form row">
                    <p className="col lead text-center mt-0 mb-5">
                        We will email you an invoice once the game session is complete.<br/>
                        <small className="font-italic font-weight-light">
                            Or choose to <span className="btn-link font-weight-light" onClick={ e => this.setState({ payment_time: 'now' }) }>pay now</span>.
                        </small>
                    </p>

                    <div className="col-10 offset-1">

                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <label className="input-group-text" style={{ width: '150px' }}>
                                    Name
                                </label>
                            </div>
                            <input
                                name="pl_name" 
                                className="form-control" 
                                onChange={this.handleChange} 
                                value={pl_name} 
                                placeholder="Instructor's name"
                                />
                        </div>

                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <label className="input-group-text" style={{ width: '150px' }}>
                                    Email
                                </label>
                            </div>
                            <input
                                name="pl_email" 
                                className="form-control" 
                                onChange={this.handleChange} 
                                value={pl_email} 
                                placeholder="Instructor's email"
                                />
                        </div>

                        {/* <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <label className="input-group-text" style={{ width: '150px' }}>
                                    Billing Address
                                </label>
                            </div>
                            <input
                                name="pl_address" 
                                className="form-control" 
                                onChange={this.handleChange} 
                                value={pl_address} 
                                placeholder="Instructor's Billing Address" />
                        </div> */}

                        <button className="btn btn-outline-primary btn-lg mt-4 mb-2" onClick={this.generatePurchase}>Create Game Session</button>

                    </div>

                    <div className="col-12 text-center mt-3">
                        <small className="form-text text-muted">
                            By confirming payment you are acknowledging that you accept our <a href="https://simcase.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>.
                        </small>
                    </div>
                </div> }

            </div>,
            <div className="text-center v-center pb-4">

                <h6 className="text-primary font-weight-bold mt-0 mb-3">
                    { result ? 'PURCHASE COMPLETED' : 'PURCHASE ERROR' }
                </h6>
                { result && <div>
                    <FontAwesomeIcon icon="check" size="4x" className="icon mt-5 mb-3 text-success" />
                    <p className="lead mt-0">
                        Your {payer} { payer === 'instructor' && payment_time === 'later' ? 'invoiced' : 'pay'} game setup was successful.
                    </p>
                    <p className="lead mt-0 mb-3 mb-lg-5">
                        Check your email to access your game session<br/>or access the game lobby below.
                    </p>
                    <div className="mt-5">
                        <a href={loginLink} className="btn btn-outline-primary mr-5">Go To Game Lobby</a>
                        <button className="btn btn-outline-primary ml-5" onClick={this.finish}>Return To Library</button>
                    </div>
                </div> }
                { !result && <div>
                    <FontAwesomeIcon icon="times-circle" size="4x" className="icon mt-5 mb-3 text-danger" />
                    <p className="lead mt-0">We had a problem with your purchase.</p>
                    <p className="lead mt-0 mb-3 mb-lg-5">
                        Try again, or contact us for support.
                    </p>
                    <div className="mt-5">
                        <button className="btn btn-outline-primary mr-5" onClick={() => this.goto(0) }>Try Again</button>
                        <a href="https://simcase.help" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary ml-5">Contact Us</a>
                    </div>
                </div> }

            </div>
        ]

        const currentContent = content[current]

        return (
            <div style={{ height: '100%' }}>

                    {/* {item.name} { payer } { quantity } { payment_time } */}

                    <div className="steps-content mb-5 pb-2" style={{ height: this.state.rvh, margin: '0 15px' }}>
                        { currentContent }
                    </div>

                    <div className="container-fluid" style={{ position: 'absolute', left: 0, bottom: '30px', width: '100%' }}>
                        <div className="row mt-3">
                            { stepBubbles }
                        </div>
                    </div>
                    
                    {/* <div className="steps-action mt-5 text-center">
                        { current > 0 && 
                            <span className="btn btn-outline-primary" onClick={ this.prev }>
                                Prev
                            </span>}
                        { current < content.length - 1 && 
                            <span className="btn btn-primary" style={{ marginLeft: '10px' }} onClick={ this.next }>
                                Next
                            </span>}
                        { current === content.length - 1 && 
                            <span className="btn btn-primary" style={{ marginLeft: '10px' }} onClick={ this.finish }>
                                Done
                            </span>}
                    </div> */}
            </div>
        );
    }

}

function mapStateToProps(state, ownProps) {
    const { item } = ownProps;
    const { user } = state.authentication;
    return { user, item };
}

export default connect(mapStateToProps)(PurchaseWizard);
