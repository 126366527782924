import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

// import AbsoluteRedirect from '../components/AbsoluteRedirect'
// import Lottie from 'react-lottie';
// import spinnerCheck from '../assets/lordicons/spinner-check.json';

// import { userActions } from '../actions';
// import { setupSocket, enterGame } from '../../helpers';

// import posed, { PoseGroup } from 'react-pose';

// import scLogo from '../assets/icon.png';
import scLogoWide from '../assets/simcase_wide.png';
// import appLogo from '../assets/app_icon.png';

const errorMessages = {
  101: 'The session you are trying to access is not available. Please get in touch with your instructor.',
  102: 'The session you are trying to access could not be found. Please get in touch with the support team.',
  403: 'You do not have permission to access this section. Please get in touch with the support team.',
};

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error_code: null,
    };
  }

  componentDidMount() {
    const query = queryString.parse(window.location.search);
    if (query.code) this.setState({ error_code: query.code });
  }

  render() {
    const { error_code } = this.state;
    const { user, loggedIn, error } = this.props;

    return (
      <div className='row bgcolors'>
        <div
          className='panel-sidebar col-3 text-center d-none d-md-block'
          style={{ height: '100vh' }}>
          <p className='v-center'>
            <Link to='/library'>
              <img
                src={scLogoWide}
                className='mb-3'
                style={{ maxWidth: '50%' }}
                alt='SimCase'
              />
            </Link>
            <br />
            <small>
              <a
                href='http://simcase.io'
                target='_blank'
                rel='noopener noreferrer'>
                Learn More
              </a>
            </small>
          </p>
        </div>
        <div
          className='col-12 col-sm-10 offset-sm-1 col-md-5 offset-md-5 text-center'
          style={{ height: '100vh' }}>
          <div className='v-center'>
            {!error_code && (
              <h3 className='font-weight-light text-white'>Loading...</h3>
            )}

            {error_code && (
              <h3 className='font-weight-light text-white mb-0'>
                {errorMessages[error_code]}
              </h3>
            )}

            {error && <h3 className='font-weight-light text-white'>{error}</h3>}

            <Link to='/dashboard' className='btn btn-outline-light btn-lg mt-5'>
              Go back to the dashboard
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user, loggingIn, loggedIn, error } = authentication;
  return {
    loggingIn,
    loggedIn,
    error,
    user,
  };
}

export default connect(mapStateToProps)(ErrorPage);
