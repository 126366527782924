import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//
import queryString from 'query-string';
//
// import numeral from 'numeral';
import moment from 'moment';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { purchasesActions } from '../../actions';

import List from '../../components/list';

import Pagination from '../../components/Pagination';

class ListPage extends React.Component {
  constructor(props) {
    let qs = queryString.parse(window.location.search);

    super(props);
    this.state = {
      items: [],
      pp: 100,
      qs: qs,
    };

    // Bindings
    this.processPurchase = this.processPurchase.bind(this);

    this.pageChange = this.pageChange.bind(this);
  }

  componentDidMount() {
    const { qs, pp } = this.state;

    const { dispatch } = this.props;
    dispatch(
      purchasesActions.getPurchases({
        sort: 'created_at:desc',
        pp,
        total: true,
        ...qs,
      })
    );
  }

  processPurchase(purchase) {
    const { dispatch } = this.props;
    dispatch(purchasesActions.processPurchase(purchase));
  }

  pageChange(pag) {
    const { qs, pp } = this.state;
    const { dispatch } = this.props;
    dispatch(
      purchasesActions.getPurchases({
        sort: 'created_at:desc',
        pp,
        pag,
        total: true,
        ...qs,
      })
    );
  }

  render() {
    const { pp, qs } = this.state;

    const { items, total } = this.props;

    const moduleItems = items.map((i, ii) => {
      return {
        client: i.client ? i.client.name : null,
        code: i.data ? i.data.client_code : null,
        customer: i.customer ? i.customer.email : null,
        platform: i.data ? i.data.platform : null,
        order: i.data ? i.data.order : null,
        status: i.data ? i.data.status : null,
        processed: i.processed ? (
          <span className='badge badge-success'>Processed</span>
        ) : (
          <span className='badge badge-warning'>Pending</span>
        ),
        created_at: (
          <small>
            {moment(i.created_at, 'YYYY-MM-DDTHH:mm:ss.SSS').format(
              'MMM Do YYYY, h:mm a'
            )}
          </small>
        ),
        // created_at: <small>{i.created_at}</small>,
        actions: [
          i.processed ? null : (
            <button
              type='button'
              key={`btn-process-${ii}`}
              onClick={() => this.processPurchase(i)}
              className='btn btn-sm btn-primary'>
              Process
            </button>
          ),
          <Link
            key={`btn-view-${ii}`}
            to={'/purchases/' + i._id}
            className='btn btn-sm btn-outline-primary'>
            Details
          </Link>,
          // <Link key={`btn-del-${ii}`} to={'/purchases/delete/' + i._id} className="btn btn-sm btn-light text-danger ml-3">
          //     <FontAwesomeIcon icon="trash-alt" size="1x" style={{ cursor: 'pointer' }} />
          // </Link>
        ],
      };
    });

    let calculoPag = total <= pp ? 1 : parseInt(total / pp, 10) + 1;
    // console.log('calculo', total);

    return (
      <div>
        <h2 className='font-weight-light mt-4'>
          Purchases
          <div className='float-right'>
            <Link to='/clients/new' className='btn btn-outline-primary'>
              Add new
            </Link>
          </div>
        </h2>
        {/* <hr/>  */}

        <div className='mt-3'>
          <List
            labels={[
              'Client',
              'Code',
              'Customer',
              'Platform',
              'Order',
              'Status',
              'Processed',
              'Created At',
              'Actions',
            ]}
            fields={[
              'client',
              'code',
              'customer',
              'platform',
              'order',
              'status',
              'processed',
              'created_at',
              'actions',
            ]}
            data={moduleItems}
          />
          <Pagination
            totalPages={calculoPag}
            pageChange={this.pageChange}
            qs={qs}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { purchases } = state;
  return {
    items: purchases.items,
    total: purchases.total,
  };
}

export default connect(mapStateToProps)(ListPage);
