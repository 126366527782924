import React from 'react'
// import { Link } from 'react-router-dom';
import MiniEditor from '../../components/MiniEditor'

class Form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            item: this.props.item || null,
            saving: 'init',
            changed: false
        }

        // Bindings
        this.handleChange = this.handleChange.bind(this)
        this.submit = this.submit.bind(this)

    }

    handleChange(e){
        let item = {...this.state.item}
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        item[e.target.name] = value
        item['changed'] = true;
        this.setState({ item })
    }

    submit(){
        const { item } = this.state;
        if( this.props.onSubmit )
            this.props.onSubmit(item)
    }

    render(){

        const { item, status } = this.state;

        return <div>

            <h2 className="row sec-title" style={{ lineHeight: 1.3 }}>
                Client: { item && item.name } <small 
                    style={{ fontSize: '12px', padding: '5px 8px', verticalAlign: 'middle' }} 
                    className={ status === 'saving' ? 'text-capitalize badge badge-pill badge-warning' : 'text-capitalize badge badge-pill badge-success' }>
                    { status === 'saving' ? 'saving...' : status }
                </small>
                <div className="ml-auto" style={{ lineHeight: 1 }}>
                    <button className="btn btn-md btn-outline-primary" onClick={ this.submit }>Save</button>
                </div>
            </h2>

            <div className="row mt-4">

                <div className="col-10 offset-1">
                    <h4>Marketplace</h4>
                    <hr/>
                    <div className="row">
                        <div className="form-group col">
                            <label className="font-weight-bold">Name</label>
                            <div className="input-group">
                                <input name="name" className="form-control" placeholder="Name"
                                    value={ item && item.name } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Subtitle</label>
                            <div className="input-group">
                                <input name="subtitle" className="form-control" placeholder="Client subtitle"
                                    value={ item && item.subtitle } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Slug</label>
                            <div className="input-group">
                                <input name="slug" className="form-control" placeholder="Library url slug"
                                    value={ item && item.slug } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Category</label>
                            <div className="input-group">
                                <input name="category" className="form-control"
                                    value={ item && item.category } onChange={this.handleChange} />
                            </div>
                        </div>

                    </div>
                    <div className="row">

                        <div className="form-group col">
                            <label className="font-weight-bold">Cover Image URL</label>
                            <div className="input-group">
                                <input name="cover_url" className="form-control" placeholder="Cover image url"
                                    value={ item && item.cover_url } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Image URL</label>
                            <div className="input-group">
                                <input name="image_url" className="form-control" placeholder="Image url"
                                    value={ item && item.image_url } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Copyright Image URL</label>
                            <div className="input-group">
                                <input name="crimg_url" className="form-control" placeholder="Copyright Image url"
                                    value={ item && item.crimg_url } onChange={this.handleChange} />
                            </div>
                        </div>

                    </div>
                    <div className="row">

                        <div className="form-group col">
                            <label className="font-weight-bold">Duration (mins)</label>
                            <div className="input-group">
                                <input name="duration" className="form-control"
                                    value={ item && item.duration } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Order</label>
                            <div className="input-group">
                                <input name="order" className="form-control" type="number"
                                    value={ item && item.order } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Price</label>
                            <div className="input-group">
                                <input name="price" className="form-control" placeholder="Price in USD"
                                    value={ item && item.price } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col-2">
                            <label className="font-weight-bold">Purchasable</label>
                            <div className="input-group">
                                <div className="custom-control custom-switch">
                                    <input 
                                        type="checkbox" 
                                        name="purchasable" 
                                        className="custom-control-input" 
                                        id="purchasable" 
                                        checked={ item && item.purchasable } 
                                        onChange={this.handleChange} />
                                    <label className="custom-control-label" htmlFor="purchasable">{ item.purchasable ? 'Yes' : 'No' }</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-2">
                            <label className="font-weight-bold">Visibility</label>
                            <div className="input-group">
                                <div className="custom-control custom-switch">
                                    <input 
                                        type="checkbox" 
                                        name="visibility" 
                                        className="custom-control-input" 
                                        id="visibility" 
                                        checked={ item && item.visibility } 
                                        onChange={this.handleChange} />
                                    <label className="custom-control-label" htmlFor="visibility">{ item.visibility ? 'Visible' : 'Hidden' }</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div className="row">
                        <div className="form-group col">
                            <label className="font-weight-bold">Description <small>(Marketplace)</small></label>
                            <MiniEditor 
                                value={ item && item.description } 
                                onUpdate={ html => this.handleChange({ target: { name: 'description', value: html } }) }/>
                        </div>
                        <div className="form-group col">
                            <label className="font-weight-bold">Overview <small>(Marketplace)</small></label>
                            {/* <div className="input-group">
                                <textarea name="overview" className="form-control" rows="3"
                                    value={ item && item.overview } onChange={this.handleChange} />
                            </div> */}
                            <MiniEditor 
                                value={ item && item.overview } 
                                onUpdate={ html => this.handleChange({ target: { name: 'overview', value: html } }) }/>
                        </div>
                    </div>

                    <h4 className="mt-5">OAuth</h4>
                    <hr/>

                    <div className="row">
                        <div className="form-group col">
                            <label className="font-weight-bold">Client ID</label>
                            <div className="input-group">
                                <input name="client_id" className="form-control" placeholder="Client ID"
                                    value={ item && item.client_id } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Client Secret</label>
                            <div className="input-group">
                                <input name="client_secret" className="form-control" placeholder="Secret"
                                    value={ item && item.client_secret } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Redirect URL</label>
                            <div className="input-group">
                                <input name="redirect_url" className="form-control" placeholder="Redirect URL"
                                    value={ item && item.redirect_url } onChange={this.handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col">
                            <label className="font-weight-bold">Public Code</label>
                            <div className="input-group">
                                <input name="public_code" className="form-control" placeholder="Public code (for 3rd parties)"
                                    value={ item && item.public_code } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Client ROOT URL</label>
                            <div className="input-group">
                                <input name="client_root" className="form-control" placeholder="Public code (for 3rd parties)"
                                    value={ item && item.client_root } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Direct URL <small>(Overwrites OAuth config)</small></label>
                            <div className="input-group">
                                <input name="direct_url" className="form-control"
                                    value={ item && item.direct_url } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group col-2">
                            <label className="font-weight-bold">Trusted</label>
                            <div className="input-group">
                                <div className="custom-control custom-switch">
                                    <input 
                                        type="checkbox" 
                                        name="trusted" 
                                        className="custom-control-input" 
                                        id="trusted" 
                                        checked={ item && item.trusted } 
                                        onChange={this.handleChange} />
                                    <label className="custom-control-label" htmlFor="trusted">{ item.trusted ? 'Yes' : 'No' }</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4 className="mt-5">Instructor Email</h4>
                    <hr/>

                    <div className="row">

                        <div className="form-group col-6">
                            <label className="font-weight-bold">Collateral</label>
                            <MiniEditor 
                                value={ item && item.res_collateral } 
                                onUpdate={ html => this.handleChange({ target: { name: 'res_collateral', value: html } }) }/>
                        </div>

                        <div className="form-group col-6">
                            <label className="font-weight-bold">Add Students</label>
                            <MiniEditor 
                                value={ item && item.res_add_students } 
                                onUpdate={ html => this.handleChange({ target: { name: 'res_add_students', value: html } }) }/>
                        </div>
                        <div className="form-group col">
                            <label className="font-weight-bold">Launch</label>
                            <MiniEditor 
                                value={ item && item.res_launch } 
                                onUpdate={ html => this.handleChange({ target: { name: 'res_launch', value: html } }) }/>
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">Debrief</label>
                            <MiniEditor 
                                value={ item && item.res_debrief } 
                                onUpdate={ html => this.handleChange({ target: { name: 'res_debrief', value: html } }) }/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="form-group col">
                            <label className="font-weight-bold">How To Video</label>
                            <input name="how_url_instructor" className="form-control" placeholder="URL to How To Video"
                                    value={ item && item.how_url_instructor } onChange={this.handleChange} />
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">FAQ</label>
                            <input name="faq_url_instructor" className="form-control" placeholder="URL to FAQs"
                                    value={ item && item.faq_url_instructor } onChange={this.handleChange} />
                        </div>
                    </div>



                    <h4 className="mt-5">Student Email</h4>
                    <hr/>

                    <div className="row">
                        <div className="form-group col">
                            <label className="font-weight-bold">How To Video</label>
                            <input name="how_url" className="form-control" placeholder="URL to How To Video"
                                    value={ item && item.how_url } onChange={this.handleChange} />
                        </div>

                        <div className="form-group col">
                            <label className="font-weight-bold">FAQ</label>
                            <input name="faq_url" className="form-control" placeholder="URL to FAQs"
                                    value={ item && item.faq_url } onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="float-right mt-3 mb-5">
                        <button className="btn btn-lg btn-primary" onClick={ this.submit }>Save</button>
                    </div>

                </div>

            </div>

        </div>
    }

}

export default Form;