import { authHeader } from '../helpers';

export const userService = {
  login,
  magiclogin,
  logout,
  register,
  requestPassword,
  userStatus,
  getAll,
  getById,
  update,
  delete: _delete,
};

function handleResponse(response) {
  return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text);
      console.log(data);
      if (!response.ok) {
        if (response.status === 401) {
          logout(); // auto logout if 401 response returned from api
          window.location.reload(true);
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    })
    .catch((err) => Promise.reject(err));
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/auth/register',
    requestOptions
  ).then(handleResponse);
}

async function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  const response = await fetch(
    process.env.REACT_APP_API_URL + '/auth/login',
    requestOptions
  );
  const user = await handleResponse(response);
  // // login successful if there's a jwt token in the response
  // if (user.token) {
  //     // store user details and jwt token in local storage to keep user logged in between page refreshes
  //     localStorage.setItem('scjwt', JSON.stringify(user));
  // }
  return user;
}

async function magiclogin(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    process.env.REACT_APP_API_URL + '/auth/autk',
    requestOptions
  );
  const user = await handleResponse(response);
  // // login successful if there's a jwt token in the response
  // if (user.token) {
  //     // store user details and jwt token in local storage to keep user logged in between page refreshes
  //     localStorage.setItem('scjwt', JSON.stringify(user));
  // }
  return user;
}

async function logout() {
  // remove user from local storage to log user out
  // localStorage.removeItem('scjwt');
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const response = await fetch(
    process.env.REACT_APP_API_URL + '/auth/logout',
    requestOptions
  );
  return handleResponse(response);
}

function requestPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/auth/recover',
    requestOptions
  ).then(handleResponse);
}

function userStatus() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/auth/profile`,
    requestOptions
  ).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    process.env.REACT_APP_API_URL + '/users/all',
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${user._id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => user);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}
