import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { history } from '../helpers';

import { Modal, ModalBody } from 'reactstrap';

// Actions
import { purchasesActions } from '../actions';

class CustomerLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = { modal_box: false };

    // Bindings
    this.showMaterials = this.showMaterials.bind(this);
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    if (user && user._id) {
      dispatch(purchasesActions.getPurchases({ customer: user._id }));

      // Display popup?
      const qs = queryString.parse(window.location.search);
      if (qs.materials && qs.materials === 'true')
        this.setState({ modal_box: true });
    }
  }

  showMaterials() {
    const { item } = this.props;
    this.setState({ modal_box: true }, () => {
      history.push(
        '/library/' + (item.slug ? item.slug : item._id) + '?materials=true'
      );
    });
  }

  render() {
    const { modal_box } = this.state;
    const { item, customer, loading } = this.props;
    const loginLink =
      item.direct_url && item.direct_url.length > 0
        ? item.direct_url
        : `/oauth/authorize?client_id=${item.client_id}&response_type=code&redirect_uri=${item.redirect_url}&scope=student`;

    if (loading || !customer) return null;

    return (
      <div>
        <a
          href={loginLink}
          className='btn btn-link btn-block text-center mt-2 text-secondary'>
          <small>Manage Sessions</small>
        </a>

        <button
          onClick={this.showMaterials}
          className='btn btn-link btn-block text-center mt-2 text-secondary'>
          <small>Materials</small>
        </button>

        <Modal
          isOpen={modal_box}
          toggle={() => this.setState({ modal_box: !modal_box })}
          size='lg'
          centered={true}>
          <ModalBody className='text-center'>
            <div style={{ padding: '0 5%' }}>
              <div style={{ height: '60vh', margin: '0' }}>
                <div className='text-center v-center'>
                  <h6 className='text-primary font-weight-bold mt-0 mb-4 text-uppercase'>
                    Materials: {item.name}
                  </h6>

                  <div className='row'>
                    <div className='col'>
                      <p className='text-center text-muted mt-3'>
                        <small>Collateral</small>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.res_collateral,
                        }}></div>

                      <p className='text-center text-muted mt-3'>
                        <small>Add Students</small>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.res_add_students,
                        }}></div>
                    </div>
                    <div className='col'>
                      <p className='text-center text-muted mt-3'>
                        <small>Launch</small>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.res_launch,
                        }}></div>

                      <p className='text-center text-muted mt-3'>
                        <small>Debrief</small>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.res_debrief,
                        }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { purchases } = state;
  return {
    customer: purchases.items.length > 0 ? true : false,
    loading: purchases.loading,
  };
}

export default connect(mapStateToProps)(CustomerLinks);
