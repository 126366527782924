import React from 'react'
// import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

// Actions
import { clientsActions } from '../../actions';

import ClientForm from './form'

class Item extends React.Component {

    constructor(props) {

        super(props);
        this.state = {}

        // Bindings
        this.submit = this.submit.bind(this)

    }

    componentDidMount(){
        const { match } = this.props;
        if( match.params._id ){
            this.props.fetchOne(match.params._id)
        }
    }

    submit(item){
        if (item._id) {
            this.props.updateItem(item)
        }else{
            this.props.createItem(item)
        }
    }

    render(){

        const { item } = this.props;

        if( !item )
            return <div className="alert alert-info mt-5">Loading...</div>

        return <ClientForm 
                item={ item }
                onSubmit={ this.submit }
                />
    }

}

function mapStateToProps(state, props) {
    const { match } = props;
    const { clients } = state;
    if (match.params._id) {
        return {
            item: clients.items.find( item => item._id === match.params._id) || null
        }
    }
    return { item: {} };
}

const actionCreators = { ...clientsActions }


export default connect(mapStateToProps, actionCreators )(Item);
