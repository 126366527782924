import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import './App.css';
import scLogoWide from './assets/simcase_wide.png';

// Actions
import { userActions } from './actions';

// import queryString from 'query-string';
import { history } from './helpers';
import Alert from './components/Alert';
import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './components/PrivateRoute';
// import { HomePage } from './containers/home';

// Public
import { HomePage } from './containers/Home';
import MagicLoginPage from './containers/MagicLogin';
import ErrorPage from './containers/Error';
import RecoveryPage from './containers/Recovery';
import RegisterPage from './containers/Register';
import LibraryPage from './containers/Library';
import LibraryItem from './containers/LibraryItem';
import ContactPage from './containers/Contact';
import LogoutPage from './containers/Logout';
// Signed-in
import ProfilePage from './containers/Profile';
import InvitesPage from './containers/invites/index';
import InviteDetailsPage from './containers/invites/item';
// Admin Routes
import UsersPage from './containers/users/index';
import SchoolsPage from './containers/schools/index';
import ClientsPage from './containers/clients/index';
import PurchasesPage from './containers/purchases';

// BaseApp Components
// import Header from './components/Header';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      finished: false,
      timeout: null,
    };

    // Bindings
  }

  componentDidMount() {
    const { dispatch, loggingOut } = this.props;

    // Loading...
    const timeout = setTimeout(() => {
      this.setState({ loading: false }, () => {
        setTimeout(() => this.setState({ finished: true }), 500);
      });
    }, 1000);
    this.setState({ timeout });

    const isLogoutUrl = window.location.pathname.includes('logout');
    if (!loggingOut && !isLogoutUrl) dispatch(userActions.userStatus());

    // Google Analytics
    const ga_id =
      process.env.NODE_ENV === 'production' ? 'UA-90111574-6' : null;
    if (ga_id) {
      ReactGA.initialize(ga_id);
      ReactGA.pageview(window.location.pathname + window.location.search);
      history.listen(function (location) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      });
    }

    setTimeout(() => {
      // TIDIO INIT
      const apikey = 'vy1zg6khtbulro1fueohizhtoeoz4e8e';
      const tidioScript = document.createElement('script');
      tidioScript.src = `//code.tidio.co/${apikey}.js`;
      document.body.appendChild(tidioScript);

      // HOTJAR INIT
      if (process.env.NODE_ENV === 'production') {
        const hjid = '1858621';
        const hotjarScript = document.createElement('script');
        hotjarScript.innerHTML = `
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:${hjid},hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
        document.body.appendChild(hotjarScript);
      }
    });
  }

  render() {
    const { loading, finished } = this.state;
    const { user } = this.props;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div id='app' className='col-12'>
            <Router history={history}>
              <div>
                <ScrollToTop />
                <Switch>
                  {/* Public Routes */}
                  <Route
                    exact
                    path='/'
                    render={(props) => <HomePage {...props} />}
                  />

                  <Route
                    exact
                    path='/login'
                    render={(props) => <HomePage {...props} />}
                  />

                  <Route
                    exact
                    path='/magiclogin'
                    render={(props) => <MagicLoginPage {...props} />}
                  />

                  <Route
                    exact
                    path='/error'
                    render={(props) => <ErrorPage {...props} />}
                  />

                  <Route
                    exact
                    path='/register'
                    render={(props) => <RegisterPage {...props} />}
                  />

                  <Route
                    exact
                    path='/recover'
                    render={(props) => <RecoveryPage {...props} />}
                  />

                  <Route
                    exact
                    path='/library'
                    render={(props) => <LibraryPage {...props} />}
                  />

                  <Route
                    path='/library/:_id'
                    render={(props) => <LibraryItem {...props} />}
                  />

                  {/* Authenticated Routes */}
                  <PrivateRoute
                    exact
                    path='/dashboard'
                    component={(props) => {
                      return user.role === 'instructor' ? (
                        <LibraryPage {...props} />
                      ) : (
                        <InvitesPage {...props} />
                      );
                    }}
                  />

                  <PrivateRoute path='/invites' component={InvitesPage} />
                  <PrivateRoute
                    path='/invites/:_id'
                    component={InviteDetailsPage}
                  />

                  <PrivateRoute path='/profile' component={ProfilePage} />
                  <Route exact path='/contact' component={ContactPage} />
                  <Route exact path='/logout' component={LogoutPage} />

                  {/* Admin Routes */}
                  <PrivateRoute path='/users' component={UsersPage} />
                  <PrivateRoute path='/clients' component={ClientsPage} />
                  <PrivateRoute path='/schools' component={SchoolsPage} />
                  <PrivateRoute path='/purchases' component={PurchasesPage} />
                </Switch>
              </div>
            </Router>
          </div>

          <div className={`${loading ? 'loading' : 'loaded'}`}>
            {!finished && (
              <div
                className='loader position-absolute text-center bg-white'
                style={{
                  height: '100vh',
                  width: '100%',
                  top: 0,
                  left: 0,
                  zIndex: 999,
                }}>
                <div className='v-center'>
                  <img
                    src={scLogoWide}
                    className='img-fluid'
                    style={{ width: '180px' }}
                    alt='SimCase'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Alert />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user, loggingOut } = authentication;
  return {
    user,
    loggingOut,
  };
}

export default connect(mapStateToProps)(App);
