import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import AbsoluteRedirect from '../components/AbsoluteRedirect';
// import Lottie from 'react-lottie';
// import spinnerCheck from '../assets/lordicons/spinner-check.json';

import { userActions } from '../actions';
// import { setupSocket, enterGame } from '../../helpers';

// import posed, { PoseGroup } from 'react-pose';

// import scLogo from '../assets/icon.png';
import scLogoWide from '../assets/simcase_wide.png';
import logoKellogg from '../assets/k_logo_100.png';
import logoHBP from '../assets/hbp-logo.svg';

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/free-regular-svg-icons';

// Add FontAwesome icons
library.add([
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
  faArrowRight,
]);

const platformLogos = {
  'sc-hub': '',
  'lti-hbp': logoHBP,
  drrc: logoKellogg,
};

class MagicLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redir: null,
      fullredir: null,
      tout: null,
      platform: 'sc-hub',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const query = queryString.parse(window.location.search);
    // console.log({ query });

    const tout = setTimeout(() => {
      dispatch(userActions.magiclogin(query));
    }, 3000);

    this.setState({
      tout,
      redir: query.redir ? query.redir : null,
      fullredir: query.fullredir ? query.fullredir : null,
      platform: query.from && platformLogos[query.from] ? query.from : 'sc-hub',
    });
  }

  componentWillUnmount() {
    const { tout } = this.state;
    if (tout) clearTimeout(tout);
  }

  render() {
    const { redir, fullredir, platform } = this.state;
    const { user, loggedIn, error } = this.props;

    console.log(user);

    if (user && user.magic && loggedIn && fullredir)
      return <AbsoluteRedirect to={decodeURIComponent(fullredir)} />;

    if (user && user.magic && loggedIn && redir)
      return <Redirect to={redir ? redir : '/dashboard'} />;

    const platforms =
      platform === 'sc-hub' ? (
        <img
          className='mb-4'
          src={scLogoWide}
          style={{ height: '50px', width: 'auto' }}
          alt='SimCase Logo'
        />
      ) : (
        <div className='mb-5'>
          <img
            className='logo-uno'
            src={platformLogos[platform]}
            style={{ height: '60px', width: 'auto' }}
            alt='Partner logo'
          />

          <FontAwesomeIcon
            icon='arrow-right'
            className='mr-4 ml-4 arrow-icon text-secondary'
          />
          <img
            className='logo-dos'
            src={scLogoWide}
            style={{ height: '60px', width: 'auto' }}
            alt='SimCase Logo'
          />
        </div>
      );

    return (
      <div className='row bgcolorsx'>
        <div className='col-12 text-center' style={{ height: '100vh' }}>
          <div className='v-center'>
            {(!loggedIn || !user.magic) && !error && (
              <div>
                {platforms}
                <h3 className='font-weight-light text-dark'>
                  Hang on, we're trying to log you in...
                </h3>
              </div>
            )}

            {loggedIn && user && user.magic && (
              <h3 className='font-weight-light text-white'>
                Welcome {user.email}!
              </h3>
            )}

            {error && <h3 className='font-weight-light text-white'>{error}</h3>}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user, loggingIn, loggedIn, error } = authentication;
  return {
    loggingIn,
    loggedIn,
    error,
    user,
  };
}

export default connect(mapStateToProps)(MagicLoginPage);
