import React from 'react'
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Actions
import { purchasesActions } from '../actions'

class FreeWizard extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            loading: true,
            modal_trial: false,
            quantity: '',
            purchase: null
        }

        // Bindings
        this.handleChange = this.handleChange.bind(this)
        this.finish = this.finish.bind(this)
        this.generatePurchase = this.generatePurchase.bind(this)

    }

    // componentDidMount() {
    //     const { user } = this.props
    // }

    handleChange(e){
        this.setState({ [e.target.name]: e.target.value })
    }

    generatePurchase(){

        const { dispatch, item, user } = this.props

        dispatch(purchasesActions.create(
            item, 
            user.email, 
            'instructor', 
            'now', 
            999,
            { free: true },
            result => { this.setState({ purchase: result }) }
        ))
    }

    finish() {
        if( typeof this.props.onFinish !== 'undefined' )
            this.props.onFinish();
        else
            console.log('Finish')
    }

    render() {

        const { quantity, purchase } = this.state;
        const { item } = this.props
        const loginLink = item.direct_url && item.direct_url.length > 0 ? item.direct_url : `/oauth/authorize?client_id=${item.client_id}&response_type=code&redirect_uri=${item.redirect_url}&scope=student`

        return <div>

            <div className="pl-3 pr-3">
                        
                <div style={{ height: '65vh', margin: '0 15px' }}>
                    <div className="text-center v-center">

                        <h6 className="text-primary font-weight-bold mt-0 mb-5" style={{ lineHeight: '1.7rem' }}>
                            { purchase ? 'PURCHASE COMPLETED' : <>{item.name}<br/>FREE ACCESS</> }
                        </h6>

                        { !purchase && <div>
                            <p className="lead mt-0 mb-3 mb-lg-5">
                                In support of instructors adapting to digital classrooms, we are offering free access to our entire library through the end of 2020.{' '}
                                We hope our exercises help make the transition easier and more engaging!
                            </p>
                            <p className="mt-5 mb-0">
                                <button className="btn btn-outline-primary btn-lg mb-3" 
                                    onClick={ this.generatePurchase }>
                                        Launch Free Session
                                </button>
                                <br/>
                                <small className="form-text text-muted">
                                    By launching a game session you are acknowledging that you accept our <a href="https://simcase.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>.
                                </small>
                            </p>
                        </div> }

                        { purchase && <div>

                            <FontAwesomeIcon icon="check" size="4x" className="icon mt-4 mb-3 text-success" />
                            <p className="lead mt-0">
                                Your game setup was successful.
                            </p>
                            <p className="lead mt-0 mb-3 mb-lg-5">
                                Check your email to access your game session<br/>or access the game lobby below.
                            </p>
                            <div className="mt-5">
                                <a href={loginLink} className="btn btn-outline-primary mr-5">Go To Game Lobby</a>
                                <button className="btn btn-outline-primary ml-5" onClick={this.finish}>Return To Library</button>
                            </div>

                        </div> }

                    </div>
                </div>

            </div>

        </div>
    }

}

function mapStateToProps(state, ownProps) {
    const { item } = ownProps;
    const { user } = state.authentication;
    return { user, item };
}

export default connect(mapStateToProps)(FreeWizard);