import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
// import moment from 'moment';
import { history } from '../helpers'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, ModalBody } from 'reactstrap';

// Actions
import { clientsActions } from '../actions';

import HeaderPublic from '../components/HeaderPublic'
import Header from '../components/Header'
import TrialButton from '../components/TrialButton'
import CustomerLinks from '../components/CustomerLinks'

import Logo from '../assets/icon.png'
import MobileFrameTop from '../assets/mobileframe_top.png'
import MobileFrameBottom from '../assets/mobileframe_bottom.png'
import PurchaseWizard from './PurchaseWizard';
import FreeWizard from './FreeWizard';

class LibraryItem extends React.Component {

    constructor(props){
        
        super(props);
        this.state = {
            items: [],
            purchase_wizard: false,
            free_wizard: false,
            modal_trial: false,
            trial: null
        }

        // Bindings

    }

    componentDidMount(){
        const { dispatch, match } = this.props;
        if( match.params._id ){
            // this.props.fetchOne(match.params._id)
            dispatch(clientsActions.getLibrary(match.params._id))
        }
    }

    render() {

        const { purchase_wizard, free_wizard, modal_trial, trial } = this.state;
        const { item, user, loggedIn } = this.props;

        const colors = {
            negotiations: 'bg-primary',
            marketing: 'bg-success',
            management: 'bg-info',
            operations: 'bg-danger',
            math: 'bg-warning'
        }

        if( !item )
            return <div className="alert alert-info mt-5">Loading...</div>

        // Update the window title
        document.title = `${item.name} - SimCase`

        const name = item ? item.name : 'No name'
        const img = item && item.cover_url && item.cover_url.length > 0 ? item.cover_url : Logo
        const overview = item ? item.overview : ''
        const preview = item ? item.image_url : ''        
        const bgColor = colors[item.category]
        // const loginLink = item.direct_url && item.direct_url.length > 0 ? item.direct_url : `/oauth/authorize?client_id=${item.client_id}&response_type=code&redirect_uri=${item.redirect_url}&scope=student`

        const libraryItem = <div key={`inv-${item._id}`}>
            <div className="card card-primary mt-5 mb-5">

                <div className={`card-header ${bgColor} text-white`} style={{ fontSize: '.8rem', padding: '7px 10px', lineHeight: 1 }}>
                    <span className="float-left">{item.duration} mins. </span>
                    <span className="float-right text-capitalize">{item.category}</span>
                </div>

                <div className="card-body">

                    <div className="mt-3 mb-4 clearfix text-center text-md-left">
                        <img src={img} className="float-none float-md-left ml-5 mr-5" style={{ width: '120px' }} alt={name} />
                        <h3 className="pt-4">{ name }</h3>
                        <p className="lead mt-2" dangerouslySetInnerHTML={({ __html: item ? item.description : '' })}></p>
                    </div>

                    <div className="row mt-3 pt-5 pb-5">
                        <div className="col-12 col-md-4 pl-5 pr-5 mt-5">
                            <div className="text-center">
                                <img src={ MobileFrameTop } className="img-fluid" alt="Mobile Frame Top" />
                                { preview && <img src={ preview } className="img-fluid" alt="Preview" /> }
                                { !preview && <img src={ Logo } className="img-fluid ml-auto mr-auto mt-5 mb-5" alt="Preview" /> }
                                <img src={ MobileFrameBottom } className="img-fluid" alt="Mobile Frame Bottom" />
                            </div>
                        </div>
                        <div className="col-12 col-md-5 pl-5 pr-5 mt-5">
                            <h4 className="mb-3">Overview</h4>
                            <div dangerouslySetInnerHTML={({ __html: overview })} ></div>
                        </div>
                        <div className="col-12 col-md-3 border-left pl-5 pr-5 mt-5">

                            <div style={{ lineHeight: '1.3rem' }}>
                                <h6><b>Topic</b>: <span className="text-capitalize">{item.category}</span></h6>
                                <h6><b>Time</b>: {item.duration} mins.</h6>
                                <h6><b>Price</b>: ${item.price} per Learner</h6>
                            </div>

                            { !user && <Link to={'/login'} className="btn btn-block btn-primary mt-5">Sign-In</Link> }
                            { !user && <Link to={'/register'} className="btn btn-block btn-link mt-3">Create an instructor account</Link> }

                            { user && user._id && user.role !== 'student' && item.purchasable && <div className="mt-5">
                                <button 
                                    onClick={ () => {
                                        if( item.price > 0 ) this.setState({ purchase_wizard: true })
                                        else this.setState({ free_wizard: true })
                                    } }
                                    className="btn btn-block btn-lg btn-primary mt-2">{ item.price > 0 ? 'Purchase' : 'Free Until 2021' }</button>
                                <hr/>
                                <TrialButton item={item} user={user} />
                                <hr/>
                                <CustomerLinks item={item} user={user} />
                            </div> }

                            { !item.purchasable && <div className="text-center mt-5">
                                <hr/>
                                {/* <small>Do you want to get access?</small> */}
                                <Link to={`/contact?m=Hi, I would like to use ${item.name} in my class.`} className="btn btn-block btn-link">Request Access</Link>
                                <hr/>
                            </div> }

                        </div>
                    </div>

                </div>
            </div>
        </div>

        return <div>

            { !loggedIn && <HeaderPublic /> }
            { loggedIn && <Header /> }

            <div className="row">
                <div className="col-12 col-md-10 offset-md-1">

                    { libraryItem }

                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                        <h4>Want to add <b>{name}</b> to your class?</h4>
                        <p className="lead">Get in touch with us today</p>
                        <Link to="/contact" className="btn btn-lg btn-outline-primary mt-3">Email Us</Link>
                    </div>

                </div>
            </div>
            
            <Modal isOpen={ purchase_wizard } toggle={ () => this.setState({ purchase_wizard: !purchase_wizard }) } size="lg" centered={ true }>
                <ModalBody className="text-center">
                    <div>
                        <PurchaseWizard 
                            item={ item } 
                            onFinish={() => {
                                this.setState({ purchase_wizard: false })
                                history.push('/library')
                            }} />
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={ free_wizard } toggle={ () => this.setState({ free_wizard: !free_wizard }) } size="lg" centered={ true }>
                <ModalBody className="text-center">
                    <div>
                        <FreeWizard 
                            item={ item } 
                            onFinish={() => {
                                this.setState({ free_wizard: false })
                                history.push('/library')
                            }} />
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={ modal_trial } toggle={ () => this.setState({ modal_trial: !modal_trial }) } size="lg" centered={ true }>
                <ModalBody className="text-center">
                    <div style={{ padding: '0 5%' }}>
                        
                        <div className="mb-5" style={{ height: '60vh', margin: '0 15px' }}>
                            <div className="text-center v-center">

                                <h6 className="text-primary font-weight-bold mt-0 mb-3 text-uppercase">TRY {item.name}</h6>

                                { !trial && <div>
                                    <p className="lead mt-0 mb-5">
                                        Explore how SimCase easily adds engagement.
                                    </p>
                                    <p className="lead mt-0 mb-5">
                                        You can set up <b>one free session</b><br/>
                                        with up to <b>10 participants</b>.
                                    </p>
                                    <p className="mt-0 mb-0">
                                        <button className="btn btn-outline-primary btn-lg mb-3" onClick={ this.createTrial }>Launch Trial</button>
                                        <br/>
                                        <small className="form-text text-muted">
                                            By confirming you are acknowledging that you accept our <a href="https://simcase.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>.
                                        </small>
                                    </p>
                                </div> }

                                { trial && <div>
                                    <p className="lead mt-0 mb-5">
                                        Great, we've created a trial game seession for you, check your email to find more details.
                                    </p>
                                    <p className="lead mt-0 mb-5">
                                        Thanks for trying <b>{item.name}</b>, we invite you to review the rest of our <a href="/library" target="_blank" rel="noopener noreferrer">catalog</a>. {' '}
                                        You can reach to use in the support chat or by sending us a message to <a href="mailto:help@simcase.io">help@simcase.io</a>.
                                    </p>
                                    <p className="lead mt-0 mb-0">
                                        <button 
                                            className="btn btn-outline-primary btn-lg mb-3" 
                                            onClick={() => this.setState({ modal_trial: false, trial: null })}>Done</button>
                                    </p>
                                </div> }

                            </div>
                        </div>

                    </div>
                </ModalBody>
            </Modal>

        </div>

    }
}

function mapStateToProps(state, ownProps) {
    const { match } = ownProps;
    const { clients } = state;
    const { user, loggedIn } = state.authentication;
    if (match.params._id) {
        return {
            user,
            loggedIn,
            item: clients.items.find( item => item._id === match.params._id || item.slug === match.params._id ) || null
        }
    }
    return { user, item: null };
}

export default connect(mapStateToProps)(LibraryItem);
