import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { invitesActions } from '../../actions';

import { Modal, ModalBody } from 'reactstrap';
import PaymentForm from '../../components/PaymentForm'

class ListItem extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false
        }

        // Bindings
        this.onPayClick = this.onPayClick.bind(this)
        this.handleStripePayment = this.handleStripePayment.bind(this)

    }

    // componentDidMount(){}

    onPayClick(){
        this.setState({ modal: true })   
    }

    handleStripePayment(charge){

        const { dispatch, item } = this.props
        fetch(process.env.REACT_APP_API_URL + '/payments/charge', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ charge, invite: item._id })
        })
        .then( response => response.json() )
        .then( res => {
            dispatch(invitesActions.getInvite( item._id ))
        })

    }

    render(){

        const { item, user, details } = this.props;
        const { modal } = this.state;

        const name = item.client ? item.client.name : 'No name'
        const img = item.client ? item.client.cover_url : ''

        const invitedBy = item.instructor;
        const invitedBySchool = invitedBy ? invitedBy.school : null;
        const schoolSponsored = invitedBySchool && invitedBySchool.free_to_play.indexOf(item.client._id) > -1 ? true : false
        const payment = item.payment;

        // Is this a purchase? Is it completed (paid)?
        let paidPurchase = item.purchase && item.purchase.data && item.purchase.data.status === 'completed' ? true : false
        // Wait! Is the student supposed to pay for it?
        paidPurchase = item.purchase && item.purchase.payer && item.purchase.payer === 'student' ? false : paidPurchase

        let paid_by = null
        let paid_on = null
        if( payment ){
            paid_by = payment.user.name + ' ' + payment.user.lastname
            paid_on = payment.created_at
        }
        if( schoolSponsored ){
            paid_by = invitedBySchool.name
            paid_on = item.created_at
        }

        // console.log({ payment, schoolSponsored, paidPurchase })

        return <div className="card card-primary mb-4">
            <div className="card-body">

                <h5 className="font-weight-normal mt-0 mb-2">
                    {name}
                    <img src={img} className="float-right ml-3" style={{ width: '80px' }} alt={name} />
                </h5>
                <p className="text-muted" dangerouslySetInnerHTML={({ __html: item.client ? item.client.description : '' })}></p>

                <p className="mt-4 mb-0">
                    <small>
                        <b>Email</b>: {item.email}<br/>
                        <b>Invited by</b>: { invitedBy && (invitedBy.name + ' ' + invitedBy.lastname) } { invitedBySchool && <i>@ { invitedBySchool.name }</i> }<br/>
                        <b>Invited on</b>: { moment(item.created_at).format('MM-DD-YY @ hh:mm') }<br/>
                        <b>Paid</b>: 
                            { paid_by && <span> By { paid_by && paid_by } on { moment(paid_on).format('MM-DD-YY @ hh:mm') }</span> }
                            { !paid_by && <span> Pending</span> }
                    </small>
                </p>

                {/* <% if( free_to_play.indexOf(String(item.client._id)) > -1 || payments.indexOf(item.client_id) > -1 || req.user.school.port_access == 'all' ){ %> */}

                {/* PLAY */}
                { (payment || schoolSponsored || paidPurchase) && <a href={ item.data.magiclink } className="btn btn-block btn-md btn-primary mt-4" target="_blank" rel="noopener noreferrer">Play</a> }

                {/* PAY */}
                { (!payment && !schoolSponsored && !paidPurchase) && <button className="btn btn-block btn-md btn-outline-primary mt-4" onClick={ () => this.onPayClick(item) }>Pay</button> }

                { details && <div className="mt-3 text-center">
                    <small>
                        <Link to={`/invites/${item._id}`} className="btn-link">View details</Link>
                    </small>
                </div> }

                <Modal isOpen={ modal } toggle={ () => this.setState({ modal: !modal }) } size="lg" centered={ true }>
                    <ModalBody className="text-center">
                        <div style={{ padding: '0 5%', height: '75vh' }}>
                            <div className="v-center pb-4">

                                <h6 className="text-primary font-weight-bold mt-0 mb-3">PAYMENT DETAILS</h6>
                                
                                <p className="lead mt-0 mb-5 ml-5 mr-5">

                                    {!payment && <span>We'll use <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a> as a secure, third-party payment platform.</span>}
                                    <br/>
                                    {!payment && <small className="font-italic font-weight-light">
                                        Pay for your license to then access your game.
                                    </small> }

                                    {payment &&  <span>Your payment was successful. You can now access the game.</span>}
                                    <br/>
                                    {payment && <small className="font-italic font-weight-light">
                                        Click the "Play" button below or return to your invitation list and play later.
                                    </small> }

                                    { payment && <a href={ item.data.magiclink } className="btn btn-block btn-md btn-primary mt-5" style={{ width: '200px', margin: '0 auto' }} target="_blank" rel="noopener noreferrer">Play</a> }

                                </p>

                                {!payment && <PaymentForm 
                                    client={item.client._id}
                                    quantity={1}
                                    fields={['name','email']}
                                    values={{ name: `${user.name} ${user.lastname}`, email: user.email }}
                                    onSuccess={ this.handleStripePayment } /> }
                                
                                <small className="form-text text-muted mt-3">
                                    By confirming payment you are acknowledging that you accept our <a href="https://simcase.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>.
                                </small>

                                {/* {payment && <div>
                                    <p className="lead mt-0 mb-5 ml-5 mr-5">
                                        You can now access your session by clicking the "Play" button in the invite.
                                    </p>
                                    <button className="btn btn-outline-primary" onClick={() => this.setState({ modal: false })}>Close</button>
                                </div> } */}

                                {/* <button className="btn btn-sm btn-link mt-3" onClick={() => this.setState({ modal: false })}>Cancel</button> */}

                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        </div>
    }

}

function mapStateToProps(state, props) {
    const { user } = state.authentication;
    return { user };
}

// const actionCreators = { ...clientsActions }

export default connect(mapStateToProps)(ListItem);
