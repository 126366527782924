import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Actions
import { clientsActions } from '../../actions';

import List from '../../components/list'

class ListPage extends React.Component {

    constructor(props){
        
        super(props);
        this.state = {
            items: []
        }

        // Bindings

    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(clientsActions.getClients())
    }

    render() {

        const { items } = this.props;

        const clientsItems = items.map( (i, ii) => {

            return {
                name: <Link key={`link-edit-${ii}`} to={'/clients/' + i._id}>{i.name}</Link>,
                subtitle: <Link key={`link-edit-${ii}`} to={'/clients/' + i._id}>{i.subtitle}</Link>,
                code: i.public_code,
                // redirect_url: i.redirect_url,
                client_id: <small><code>{i.client_id}</code></small>,
                client_secret: <small><code>{i.client_secret}</code></small>,
                // cover_url: i.cover_url,
                visibility: i.visibility.toString(),
                order: i.order,
                created_at: <small>{moment(i.created_at).format('MMM Do YYYY, h:mm a')}</small>,
                actions: [
                    <Link key={`btn-edit-${ii}`} to={'/clients/' + i._id} className="btn btn-sm btn-outline-primary">Edit</Link>,
                    <Link key={`btn-del-${ii}`} to={'/clients/delete/' + i._id} className="btn btn-sm btn-light text-danger ml-3">
                        <FontAwesomeIcon icon="trash-alt" size="1x" style={{ cursor: 'pointer' }} />
                    </Link>
                ]
            }
        })

        return (
            <div>

                <h2 className="font-weight-light mt-4">
                    Clients
                    <div className="float-right">
                        <Link to="/clients/new" className="btn btn-outline-primary">Add new</Link>
                    </div>
                </h2>
                {/* <hr/>  */}

                <div className="mt-3">
                    <List 
                        labels={['#', 'Name', 'Subtitle', 'Code', 'Client_Id', 'Secret', 'Visible', 'Created_At', 'Actions']}
                        fields={['order', 'name', 'subtitle', 'code', 'client_id', 'client_secret', 'visibility', 'created_at', 'actions']}
                        data={ clientsItems } 
                        size="table-sm"
                        />
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { clients } = state;
    return {
        items: clients.items
    };
}

export default connect(mapStateToProps)(ListPage);
