import { userActions } from './';
import { authHeader, handleJSONResponse, store, history } from '../helpers';

// Inner Helpers
const checkJSONRequest = (response) => {
  const dispatch = store.dispatch;
  return handleJSONResponse(response, (data, code) => {
    if (code === 401) dispatch(userActions.logout());
    if (code !== 401) history.push('/error?code=403');
  });
};

export const FETCHONE_SUCCESS = 'PURCHASES_FETCHONE_SUCCESS';
export const GETALL_REQUEST = 'PURCHASES_GETALL_REQUEST';
export const GETALL_SUCCESS = 'PURCHASES_GETALL_SUCCESS';
export const GETALL_FAILURE = 'PURCHASES_GETALL_FAILURE';
export const CREATEONE_SUCCESS = 'PURCHASES_CREATEONE_SUCCESS';
export const UPDATEONE_SUCCESS = 'PURCHASES_UPDATEONE_SUCCESS';

export const purchasesActions = {
  create,
  getPurchase: fetchOne,
  // updateItem,
  getPurchases: getAll,
  processPurchase: processPurchase,
  getSession,
  // delete: _delete
};

function fetchOne(id) {
  return (dispatch) => {
    fetch(`/api/purchases/${id}`, {
      method: 'GET',
      headers: authHeader(),
    })
      .then(checkJSONRequest)
      .then((data) => dispatch(success(data[0] || null)));
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(item) {
    return { type: FETCHONE_SUCCESS, item };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function create(item, customer, payer, payment_time, quantity, metadata, cb) {
  const purchaseData = {
    client_code: item.public_code,
    customer: customer,
    date: Date(),
    method: 'invoiceable',
    order: Date.now(),
    platform: 'sc-hub',
    price: item.price,
    quantity: quantity,
    status: 'completed',
    total: item.price * quantity,
    payer: payer,
    pay_later: payment_time === 'later' ? true : false,
    metadata,
  };

  // console.log({ purchaseData })

  return (dispatch) => {
    fetch(`/api/integrations/purchase`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify(purchaseData),
    })
      .then(checkJSONRequest)
      .then((data) => {
        dispatch(success(data || null));
        if (cb) cb(data);
      });
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(purchase) {
    return { type: CREATEONE_SUCCESS, item: purchase };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function getAll(filters) {
  return (dispatch) => {
    dispatch(request());

    // const qs = queryString.stringify(params)
    const qs = Object.keys(filters)
      .filter((key) => !isNaN(filters[key]) || filters[key].length > 0)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');

    fetch(
      process.env.REACT_APP_API_URL + '/purchases/all' + (qs ? '?' + qs : ''),
      {
        method: 'GET',
        headers: authHeader(),
      }
    )
      .then(checkJSONRequest)
      .then(
        (items) => dispatch(success(items)),
        (error) => dispatch(failure(error))
      );
  };

  function request() {
    return { type: GETALL_REQUEST };
  }
  function success(items) {
    return { type: GETALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: GETALL_FAILURE, error };
  }
}

function processPurchase(purchase) {
  return (dispatch) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/purchases/${purchase._id}/process`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
      }
    )
      .then(checkJSONRequest)
      .then((item) => dispatch(success(item)));

    // function request() { return { type: GETALL_REQUEST } }
    function success(item) {
      return { type: UPDATEONE_SUCCESS, item: item };
    }
    // function failure(error) { return { type: GETALL_FAILURE, error } }
  };
}

function getSession(purchase_id) {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_URL}/purchases/${purchase_id}/session`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    })
      .then(checkJSONRequest)
      .then((item) => {
        console.log(item);
      });

    // function request() { return { type: GETALL_REQUEST } }
    // function success(item) {
    //   return { type: UPDATEONE_SUCCESS, item: item };
    // }
    // function failure(error) { return { type: GETALL_FAILURE, error } }
  };
}
